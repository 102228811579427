<template>
  <MainLayout>
    <b-container class="m-0 p-0">
      <b-row v-if="!logged_in"
             no-gutters
             id="landing1">
        <b-col>
          <b-row>
            <b-col id="landing1_content">
              <div id="header1">Watch any crypto wallet with ease.</div>

              <div v-if="mq_lt_lg" id="landing_img_wrapper">
                <img id="landing_img"
                     :src="require('@/assets/landing.svg')" />
              </div>

              <div id="subtext1">
                With a few clicks, setup an alert to be notified whenever someone sends a payment to or from any wallet. Knowledge is power, don't miss out on important activity.
              </div>

              <LandingWatchParams v-if="!mq_lg"/>
            </b-col>

            <b-col v-if="mq_gte_lg" id="landing_img_wrapper">
              <img id="landing_img" :src="require('@/assets/landing.svg')" />
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <LandingWatchParams v-if="mq_lg"/>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-row v-if="!logged_in"
             no-gutters
             id="landing2">
        <b-col>
          <div id="header2">How it works</div>
          <div id="subtext2">Receive an email whenever a payment is sent to or from any wallet!</div>

          <div id="steps">
            <div id="step1">
              <img :src="require('@/assets/landing-step1.svg')" />
              <div>Select a Blockchain</div>
            </div>

            <div id="step2">
              <img :src="require('@/assets/landing-step2.svg')" />
              <div>Specify the wallet</div>
            </div>

            <div id="step3">
              <img :src="require('@/assets/landing-step3.svg')" />
              <div>Enter email to receive alerts</div>
            </div>
          </div>
        </b-col>
      </b-row>

      <b-row v-if="logged_in" no-gutters>
        <b-col>
          <MyWatches />
        </b-col>
      </b-row>
    </b-container>
  </MainLayout>
</template>

<script>
import MainLayout         from './components/MainLayout'
import LandingWatchParams from './components/LandingWatchParams'
import MyWatches          from './components/MyWatches'

import Authentication     from './mixins/authentication'

export default {
  name: 'Landing',

  mixins : [Authentication],

  components : {
    MainLayout,
    LandingWatchParams,
    MyWatches
  }
}
</script>

<style scoped>
.container{
  max-width: unset;
}

#landing1{
  background-color: var(--theme-color1);
  padding-bottom: 50px;
}

#landing2{
  background-color: var(--theme-color4);
  padding: 50px 0;
}

#landing1_content{
  margin-left: 50px;
  max-width: 650px;
}

#main_layout.md #landing1_content{
  max-width: unset;
}

#main_layout.md #landing1_content,
#main_layout.sm #landing1_content,
#main_layout.xs #landing1_content{
  max-width: unset;
  margin-left: 15px;
}

#header1{
  font-family: var(--theme-font1);
  font-size: 75px;
  font-weight: bold;
  line-height: 78px;
  letter-spacing: -0.5px;
  color: var(--theme-color2);
  margin: 15px 0;
}

#main_layout.sm #header1,
#main_layout.xs #header1{
  margin-left: 10px;
  font-size: 50px;
  line-height: 55px;
}

#subtext1{
  font-family: var(--theme-font1);
  font-size: 20px;
  color: var(--theme-color2);
  margin: 35px 0;
}

#main_layout.sm #subtext1,
#main_layout.xs #subtext1{
  margin-left: 20px;
  margin-right: 20px;
}

#landing_img_wrapper{
  text-align: center;
  padding-right: 15px;
  padding-top: 15px;
}

#main_layout.xl #landing_img_wrapper{
  align-self: baseline;
}

#main_layout.lg #landing_img_wrapper{
  padding-top: unset;
}

#main_layout.sm #landing_img_wrapper,
#main_layout.xs #landing_img_wrapper{
  padding-top: unset;
}

#landing_img{
  width: 100%;
  height: 100%;
}

#steps{
  display: flex;
  justify-content: space-evenly;
  font-family: var(--theme-font1);
  color: var(--theme-color1);
  text-align: center;
  margin-top: 25px;
}

#main_layout.xl #steps,
#main_layout.lg #steps{
  margin-left: 15%;
  margin-right: 15%;
}

#main_layout.sm #steps,
#main_layout.xs #steps{
  flex-direction: column;
}

#steps img{
  max-width: 100%;
}

#header2{
  text-align: center;
  font-family: var(--theme-font1);
  color: var(--theme-color1);
  font-size: 48px;
  line-height: 54px;
}

#subtext2{
  text-align: center;
  font-family: var(--theme-font1);
  color: var(--theme-color1);
  font-size: 20px;
  line-height: 24px;
  margin-top: 15px;
}
</style>
