<template>
  <b-modal id="confirm_delete_modal"
           ref="confirm_delete_modal"
           ok-title="DELETE"
           cancel-title="CANCEL"
           centered
           @ok="$emit('confirmed')"
           @cancel="$emit('cancelled')">
    <div>Are you sure?</div>
  </b-modal>
</template>

<script>
export default{
  name : "ConfirmDeleteModal"
}
</script>

<style scoped>
div{
  font-family: var(--theme-font1);
  font-size: 24px;
  line-height: 28px;
  text-align: center;
}
</style>

<style>
#confirm_delete_modal___BV_modal_footer_ {
  justify-content: space-evenly;
}

#confirm_delete_modal___BV_modal_footer_ button{
  width: 45%;
}

#confirm_delete_modal___BV_modal_footer_ .btn-primary{
  background-color: var(--theme-color11);
  border-color: var(--theme-color11);
}

#confirm_delete_modal___BV_modal_footer_ .btn-secondary{
  background-color: var(--theme-color1);
  border-color: var(--theme-color3);
  color: var(--theme-color3);
}
</style>
