export default {
  computed : {
    auth_token (){
      return this.$cookies.authToken;
    },

    auth_header (){
      return {headers : {authorization : this.auth_token}};
    },

    logged_in (){
      return !!this.auth_token;
    }
  }
}
