<template>
  <div id="main_layout" :class="class_obj">
    <MainHeader />

    <div id="main_content">
      <slot></slot>
    </div>

    <MainFooter />
  </div>
</template>

<script>
import MainHeader     from './MainHeader'
import MainFooter     from './MainFooter'
import Authentication from '../mixins/authentication'
import ServerAPI      from '../mixins/server_api'

export default {
  name: 'MainLayout',

  mixins : [
    Authentication,
    ServerAPI
  ],

  components : {
    MainHeader,
    MainFooter
  },

  computed : {
    class_obj() {
      var _class = {logged_in : this.logged_in};
          _class[this.$mq] = true;
      return _class;
    }
  },

  created : function(){
    if(this.auth_token)
      this.load_user();
  }
}
</script>

<style>
#main_layout{
  position: relative;
  min-height: 100vh;
}

#main_layout.logged_in{
  background-color: var(--theme-color8);
}

#main_content{
  padding-bottom: 150px; /* same as footer height */
}
</style>
