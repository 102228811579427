<template>
  <div>
    <div id="blockchain_param" class="param">
      <div>Select a Blockchain</div>

      <div>
        <div id="blockchain">
          <multiselect v-model="blockchain"
                       :options="blockchains"
                       :allow-empty="false"
                       :searchable="false"
                       placeholder=""
                       selectLabel=""
                       deselectLabel=""
                       selectedLabel="">
            <template slot="singleLabel" slot-scope="props">
              <CurrencyIcon :currency="props.option.toUpperCase()" />
              {{props.option.toUpperCase()}}
            </template>

            <template slot="option" slot-scope="props">
              <CurrencyIcon :currency="props.option.toUpperCase()" />
              {{props.option.toUpperCase()}}
            </template>
          </multiselect>
        </div>
      </div>

      <div v-if="blockchain_specified"
           id="wallet_param" class="param">
        <div>Choose a wallet</div>

        <div id="wallet">
          <multiselect v-model="wallet"
                       :options="wallets"
                       :allow-empty="false"
                       :searchable="false"
                       placeholder="Select Wallet"
                       selectLabel=""
                       deselectLabel=""
                       selectedLabel=""
                       :maxHeight="450">
            <template slot="singleLabel" slot-scope="props">
              {{props.option.label}}
            </template>

            <template slot="option" slot-scope="props">
              {{props.option.label}}
            </template>
          </multiselect>
        </div>
      </div>

      <div v-if="blockchain_specified"
           id="address_param"
           class="param">
        <div>
          <span v-if="custom_wallet">Enter a</span><span v-else>A</span>ddress
        </div>

        <div id="address">
          <b-form-input placeholder="Address"
                        :disabled="!custom_wallet"
                        v-model="address" />
        </div>

      <div v-if="show_address_error" class="error">
        Invalid address
      </div>
      </div>
    </div>

    <div id="email_param" class="param">
      <div>Send email notification to:</div>

      <div>
        <b-form-input v-model="email" />
      </div>

      <div v-if="show_email_error" class="error">
        Invalid email
      </div>

      <div v-if="show_sink_error" class="error">
        Max emails created, must delete or reuse one
      </div>
    </div>
  </div>
</template>

<script>
import CurrencyIcon   from './CurrencyIcon'
import Authentication from '../mixins/authentication'
import ServerAPI      from '../mixins/server_api'
import config         from '../config/config'
import util           from '../util'

const wallets   = require("../assets/wallets.json")
const templates = require("../assets/templates.json")

import ziti from '../config/ziti'

export default {
  name: 'WatchParams',

  mixins : [
    Authentication,
    ServerAPI
  ],

  components : {
    CurrencyIcon
  },

  data () {
    return {
       blockchain : '',
           wallet : '',
          address : '',
            email : ''
    }
  },

  computed : {
    blockchains () {
      return config.BLOCKCHAINS;
    },

    blockchain_specified () {
      return this.blockchain != '';
    },

    wallets () {
      var _wallets = wallets[this.blockchain].slice();
          _wallets.unshift({address : "", label : "Custom"})
      return _wallets;
    },

    custom_wallet () {
      return this.wallet.address == '';
    },

    ///

    has_blockchain () {
      return this.blockchain != '';
    },

    has_wallet () {
      return this.wallet != '';
    },

    has_address () {
      return this.address != '';
    },

    has_email () {
      return this.email != '';
    },

    valid_address(){
      return this.has_address && util.is_valid_wallet(this.blockchain, this.address);
    },

    valid_email(){
      return this.has_email && util.is_valid_email(this.email);
    },

    valid_sink(){
      // If we're not logged in, assume we can create sink
      if(!this.logged_in) return true;

      // Retrieve number of sinks user is allowed to create
      const membership_level = this.$store.state.user.membership_level;
      const allowed_sinks = ziti.membership_features[membership_level].sinks;

      // Retrieve existing sinks and emails
      const sinks = this.$store.state.sinks;
      const emails = sinks.filter((s) => s.type == "email")
                          .map((s) => s.target);

      // Determine if user can create new sink
      const can_create = sinks.length < allowed_sinks;

      // Sink valid if user can create or if existing
      return can_create || emails.includes(this.email);
    },

    is_valid () {
       return this.has_blockchain &&
              this.has_wallet     &&
              this.has_address    &&
              this.valid_address  &&
              this.has_email      &&
              this.valid_email    &&
              this.valid_sink;
    },

    show_address_error(){
      return this.has_address && !this.valid_address;
    },

    show_email_error(){
      return this.has_email && !this.valid_email;
    },

    show_sink_error(){
      return this.has_email && this.valid_email && !this.valid_sink;
    },

    ///

    sink (){
      return this.$store.state.sinks.find((s) => s.type == 'email' && s.target == this.email);
    },

    sink_params () {
      return {
          type: 'email',
        target: this.email
      };
    },

    filter_name () {
      return 'WalletWatch: ' + (this.custom_wallet ?
                                      this.address :
                                 this.wallet.label);
    },

    filter_wallet () {
      return this.custom_wallet ? this.address : this.wallet.address;
    },

    filter_params () {
      var _filter_params = {
              name : this.filter_name,
        blockchain : this.blockchain,
          template : templates[this.blockchain],
            params : [this.filter_wallet]
      }

      if(this.sink) _filter_params.sinks = [this.sink.id];

      return _filter_params;
    },

    in_progress_filter () {
      return this.$store.state.in_progress_filter;
    }
  },

  watch : {
    blockchain() {
      this.wallet  = '';
      this.address = '';
    },

    in_progress_filter() {
      // Reset form if in_progress_filter cleared
      if(!this.in_progress_filter.name)
        this.reset();
    },

    wallet(){
      if(this.has_wallet && !this.custom_wallet)
        this.address = this.wallet.address;
    }
  },

  methods : {
    reset() {
      this.blockchain = '';
      this.wallet     = '';
      this.address    = '';
      this.email      = '';
    },

    submit() {
      if(!this.is_valid) return;

      // If not logged in
      if(!this.logged_in){
        // Store in progress sink and filter
        this.$store.commit('set_in_progress_sink',   this.sink_params);
        this.$store.commit('set_in_progress_filter', this.filter_params);

        // Show login form
        this.$bvModal.show('login_modal');

        return;
      }

      // If sink doesn't exist, create it
      if(!this.sink){
        this.create_sink(this.sink_params)
            .then(function(){
              this.load_sinks()
                  .then(function(){
                    this.submit();
                  }.bind(this));
            }.bind(this));
        return;
      }

      // Create filter and reset component
      this.create_filter(this.filter_params)
          .then(function(){
            this.load_filters()
          }.bind(this))
      this.reset();
    }
  }
}
</script>

<style scoped>
.param{
  padding: 5px 0;
  font-family: var(--theme-font1);
}

.error{
  font-family: var(--theme-font1);
  color: var(--theme-color11);
}
</style>
