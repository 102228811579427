import Vue from 'vue'
Vue.config.productionTip = false

///

// Bootstrap components and styling
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(BootstrapVue)

///

require('./mq')

///

// Vue HTTP Client
import VueResource from 'vue-resource'
Vue.use(VueResource)

///

// Cookie Support
import VueReactiveCookie from 'vue-reactive-cookie'
Vue.use(VueReactiveCookie, {convertJSON : true})

///

// Multiselect component
import Multiselect from 'vue-multiselect'
import "vue-multiselect/dist/vue-multiselect.min.css"
Vue.component('multiselect', Multiselect)

///

// Common site-wide Styling
import("../public/common.css")

///

import store  from './store'
import router from './router'

new Vue({
  router : router,
   store : store
}).$mount('#app')
