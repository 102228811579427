<template>
  <div id="my_alerts">
    <div id="col1">
      <div id="header">My watchlist</div>

      <b-button id="add_new"
                :disabled="!can_create"
                v-b-modal.new_watch_modal>
        <span v-if="can_create">
          ADD NEW
        </span>

        <span v-else>
          Max watches created
        </span>
      </b-button>
    </div>

    <div id="col2">
      <template v-if="have_alerts">
        <div class="alert"
             v-for="alert in alerts"
             :key="alert.id">
          <div>
            <div class="label">Blockchain</div>
            <div>
              <CurrencyIcon :currency="alert.blockchain.toUpperCase()" />
              {{alert.blockchain.toUpperCase()}}
            </div>
          </div>

          <div>
            <div class="label">Wallet</div>
            <div v-if="alert.name != alert.params[0]">{{alert.name}}</div>
            <div>{{alert.params[0].substr(0, mq_lt_md ? 20 : 7)}}...</div>
          </div>

          <div>
            <div class="label">Email</div>
            <div>{{alert.Sinks[0].target}}</div>
          </div>

          <div class="delete_filter">
            <img :src="require('@/assets/delete.svg')"
                 v-b-modal.confirm_delete_modal
                 @click="delete_target = alert.id" />
          </div>
        </div>
      </template>

      <div v-else
           id="no_alerts">
        <img :src="require('@/assets/no-watches.svg')" />
        <div>You haven't created any alerts yet</div>
      </div>
    </div>

    <NewWatchModal />
    <ConfirmDeleteModal @confirmed="delete_watch()"
                        @cancelled="delete_target = null"/>
  </div>
</template>

<script>
import CurrencyIcon       from './CurrencyIcon'
import NewWatchModal      from './NewWatchModal'
import ConfirmDeleteModal from './ConfirmDeleteModal'

import Authentication     from '../mixins/authentication'
import ServerAPI          from '../mixins/server_api'

import ziti               from '../config/ziti'

export default{
  name : "MyAlerts",

  components : {
    CurrencyIcon,
    NewWatchModal,
    ConfirmDeleteModal
  },

  mixins : [
    Authentication,
    ServerAPI
  ],

  data () {
    return {
      delete_target : null
    }
  },

  computed : {
    can_create() {
      if(!this.$store.state.user.membership_level) return false;

      const membership_level = this.$store.state.user.membership_level;
      const membership = ziti.membership_features[membership_level];
      const filters_allowed = membership.filters;
      const num_filters = this.$store.state.filters.length;
      return num_filters < filters_allowed;
    },

    alerts () {
      return this.$store
                 .state
                 .filters
                 .filter((f) => f.name.match(/WalletWatch:.*/))
                 .map((f) => {
                   f.name = f.name.replace("WalletWatch: ", "")
                   return f;
                 })
    },

    have_alerts () {
      return this.alerts.length > 0;
    }
  },

  methods : {
    delete_watch (){
      this.delete_filter(this.delete_target)
          .then(function(){
            this.load_filters();
          }.bind(this))
    }
  }
}
</script>

<style scoped>
#my_alerts{
  display: flex;
  justify-content: center;
  width: 95%;
  margin: auto;
  margin-top: 59px;
}

#main_layout.sm #my_alerts,
#main_layout.xs #my_alerts{
  flex-direction: column;
}

#col1{
  flex-basis: 35%;
}

#main_layout.xl #col1{
  flex-basis: 25%;
}

#col2{
  flex-basis: 60%;
  margin-top: 15px;
}

#main_layout.xl #col2{
  flex-basis: 70%;
}

#header{
  font-family: var(--theme-font1);
  color: var(--theme-color2);
  font-weight: bold;
  font-size: 28px;
  font-size: 40px;
  letter-spacing: 0.5px;
  margin-bottom: 15px;
}

#add_new{
  font-family: var(--theme-font2);
  font-size: 14px;
  font-weight: bold;
  line-height: 16px;
  letter-spacing: 1px;
  background-color: var(--theme-color3);
  border: 1px solid var(--theme-color3);
}

#add_new:hover{
  background-color: var(--theme-color6);
  border: 1px solid var(--theme-color6);
}

#add_new.disabled{
  background-color: var(--theme-color7);
  border: 1px solid var(--theme-color7);
}

.alert{
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  border: 1px solid var(--theme-color10);
  border-radius: 8px;
  font-family: inter;
}

#main_layout.sm .alert,
#main_layout.xs .alert{
  flex-direction: column;
}

.label{
  color: var(--theme-color9);
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 5px;
}

#main_layout.sm .label,
#main_layout.xs .label{
  margin-top: 15px;
}

.delete_filter{
  display: flex;
  align-items: center;
  cursor: pointer;
  opacity: 0.7;
}

#main_layout.sm .delete_filter,
#main_layout.xs .delete_filter{
  margin-top: 15px;
}

.delete_filter:hover{
  opacity: unset;
}

#no_alerts{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 394px;

  text-align: center;
  font-family: var(--theme-font1);
  font-size: 20px;
  line-height: 24px;
  color: var(--theme-color9);
}

#no_alerts img{
  width: 350px;
  margin-bottom: 25px;
}
</style>
