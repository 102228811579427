<template>
  <footer>
    <div id="powered_by" class="footer">
      <b-link href="https://blocktr.ac"
              target="_blank">
        <img :src="require('@/assets/logo-text-powered.svg')">
      </b-link>

      <div id="footer_links">
        <b-link href="https://blocktr.ac/about#contact"
                target="_blank">
          Help &amp; Support
        </b-link>

        <b-link href="https://blocktr.ac/terms"
                target="_blank">
          Terms of service
        </b-link>
      </div>
    </div>

    <div id="dnp" class="footer">
      <b-link href="https://devnullprod.com"
              target="_blank">
        <img :src="require('@/assets/dnp.svg')">

        <div>
          A Dev Null Productions Service
        </div>
      </b-link>
    </div>
  </footer>
</template>

<script>
export default{
  name : "MainFooter"
}
</script>

<style scoped>
footer{
  position: absolute;
  bottom: 0;
  height: 150px; /* same as main content padding-bottom */
  width: 100%;

  background: var(--theme-color1);
  font-family: var(--theme-font1);
  font-size: 16px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 150px;
}

#main_layout.sm footer,
#main_layout.xs footer{
  flex-direction: column;
  padding: 25px 25px;
}

.footer{
  display: flex;
  flex-direction: column;
}

#powered_by img{
  height: 57px;
}

#footer_links{
  width: 273px;
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
}

#dnp a{
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--theme-color2);
  text-decoration: none;
}

#dnp img{
  height: 80px;
  width: 80px;
}

#main_layout.sm #dnp,
#main_layout.xs #dnp{
  margin-top: 50px;
  padding-bottom: 25px;
}
</style>
