import Vue  from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state : {
    global_result : '',
     global_error : '',

       user : {},
    filters : [],
      sinks : [],

      in_progress_sink : {},
    in_progress_filter : {}
  },

  mutations: {
    set_global_result(state, result){
      state.global_result = result;
    },

    set_global_error(state, err){
      state.global_error = err;
    },

    set_user(state, user){
      state.user = user;
    },

    set_filters(state, filters){
      state.filters = filters;
    },

    set_sinks(state, sinks){
      state.sinks = sinks;
    },

    set_in_progress_sink(state, sink){
      state.in_progress_sink = sink;
    },

    set_in_progress_filter(state, filter){
      state.in_progress_filter = filter;
    },

    clear_in_progress_sink(state){
      state.in_progress_sink = {};
    },

    clear_in_progress_filter(state){
      state.in_progress_filter = {};
    }
  }
});
