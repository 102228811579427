<template>
  <b-navbar sticky>
    <b-navbar-brand to="/">
      <img :src="require('@/assets/logo-text.svg')" />
    </b-navbar-brand>

    <b-navbar-nav v-if="!logged_in && mq_gte_sm"
                  id="anon_nav" class="ml-auto">
      <b-nav-item v-b-modal.login_modal>
        <b-button id="login">
          LOG IN
        </b-button>
      </b-nav-item>

      <b-nav-item v-b-modal.register_modal>
        <b-button id="register">
          SIGN UP
        </b-button>
      </b-nav-item>
    </b-navbar-nav>

    <template v-if="!logged_in && mq_lt_sm">
      <b-navbar-nav class="ml-auto">
        <b-nav-item @click="show_hamburger = true">
          <img id="hamburger_open_icon" src="../assets/hamburger.svg" />
        </b-nav-item>
      </b-navbar-nav>

      <div id="hamburger_nav" v-show="show_hamburger">
        <div id="hide_hamburger" @click="show_hamburger = false">
          <img id="hamburger_close_icon" src="../assets/close.svg" />
        </div>

        <div id="hamburger_title">
          <img :src="require('@/assets/logo-text.svg')" />
        </div>

        <b-button id="login"
                  @click="show_hamburger = false"
                  v-b-modal.login_modal>
          LOG IN
        </b-button>

        <b-button id="register"
                  @click="show_hamburger = false"
                  v-b-modal.register_modal>
          SIGN UP
        </b-button>
      </div>
    </template>

    <b-navbar-nav v-if="logged_in"
                  id="logged_in_nav"
                  class="ml-auto">
      <b-nav-item id="logout" @click="logout">
        <img :src="require('@/assets/logout.svg')" />
      </b-nav-item>
    </b-navbar-nav>

    <LoginModal />
    <RegisterModal />
    <ResultModal />
    <ErrorModal  />
  </b-navbar>
</template>

<script>
import LoginModal     from './LoginModal'
import RegisterModal  from './RegisterModal'
import ResultModal    from './ResultModal'
import ErrorModal     from './ErrorModal'

import Authentication from '../mixins/authentication'
import ServerAPI      from '../mixins/server_api'

export default {
  name: 'MainNav',

  mixins : [
    Authentication,
    ServerAPI
  ],

  components : {
    LoginModal,
    RegisterModal,
    ResultModal,
    ErrorModal
  },

  data (){
    return {
      show_hamburger : false
    }
  }
}
</script>

<style scoped>
nav{
  background: var(--theme-color1);
}

#watchlist{
  font-family: var(--theme-font1);
  font-weight: 800;
  letter-spacing: 0.5px;
}

#anon_nav #watchlist{
  color: var(--theme-color2);
}

#login_nav #watchlist{
  color: var(--theme-color3);
}

#login {
  background-color: var(--theme-color1);
  border: 1px solid var(--theme-color3);
  border-radius: 4px;
  color: var(--theme-color3);
  font-family: var(--theme-font2);
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 1px;
}

#login:hover {
  background-color: var(--theme-color5);
}

#register {
  background-color: var(--theme-color3);
  border: 1px solid var(--theme-color3);
  border-radius: 4px;
  color: var(--theme-color1);
  font-family: var(--theme-font2);
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 1px;
}

#register:hover {
  background-color: var(--theme-color6);
  border: 1px solid var(--theme-color6);
}

#hamburger_nav #register{
  background-color: var(--theme-color1);
  border: 1px solid var(--theme-color3);
  color: var(--theme-color3);
  margin-top: 25px;
}

#hamburger_nav #register:hover{
  background-color: var(--theme-color5);
}

#hamburger_open_icon,
#hamburger_close_icon{
  cursor: pointer;
}

#hamburger_nav{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;

  background-color: var(--theme-color3);
  padding: 20px;

  display: flex;
  flex-direction: column;
}

#hide_hamburger{
  cursor: pointer;
  text-align: right;
}

#hamburger_close_icon{
  width: 25px;
}

#hamburger_title{
  margin-top: 30px;
  margin-bottom: 25px;
  text-align: center;
  background-color: var(--theme-color1);
  padding: 15px;
  border-radius: 15px;
}
</style>
