export default {
  data () {
    return {
      is_mounted : false
    }
  },

  mounted () {
    this.is_mounted = true;
  }
}
