<template>
  <span class="currency_icon">
    <img :src="src">
  </span>
</template>

<script>
export default {
  name: 'CurrencyIcon',

  props : {
    currency: {
      type : String,
      required : true
    },
  },

  computed : {
    src : function(){
      return require("@/assets/currencies/" + this.currency + ".svg");
    }
  }
}
</script>

<style scoped>
.currency_icon{
  margin-left: 3px;
}

.currency_icon img{
  height: 15px;
  width: 15px;
  margin-bottom: 2px
}
</style>
