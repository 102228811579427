<template>
  <div id="landing_watch_params">
    <div id="landing_watch_params_container">
      <div id="header1">Create Alert</div>

      <WatchParams ref="watch_params" />

      <div id="submit">
        <b-button @click="submit" :disabled="!is_valid">CREATE ALERT</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import WatchParams from './WatchParams'
import IsMounted   from '../mixins/is_mounted'

export default {
  name: 'LandingWatchParams',

  components : {
    WatchParams
  },

  mixins : [
    IsMounted
  ],

  computed : {
    is_valid () {
      if(!this.is_mounted) return false;

      return this.$refs.watch_params.is_valid;
    }
  },

  methods : {
    submit () {
      this.$refs.watch_params.submit();
    }
  }
}
</script>

<style scoped>
#landing_watch_params{
  box-shadow: 0px 8px 20px 15px rgba(0, 0, 0, 0.15);
  background: linear-gradient(180deg, #6BBAFF 0%, #6CFF7B 100%);
  border-radius: 15px;
  padding: 3px;
  margin-right: 15px;
}

#main_layout.lg #landing_watch_params{
  width: 65%;
  margin: auto;
}

#main_layout.sm #landing_watch_params,
#main_layout.xs #landing_watch_params{
  margin-left: 20px;
  margin-right: 20px;
}

#landing_watch_params_container{
  padding: 40px;
  border-radius: 15px;
  background-color: var(--theme-color1);
}

#header1{
  font-family: var(--theme-font1);
  font-weight: bold;
  font-size: 34px;
  line-height: 40px;
  letter-spacing: 0.5px;
  padding: 0 0 15px 0;
}

#submit{
  margin-top: 15px;
}

#submit button{
  width: 100%;
  font-family: var(--theme-font2);
  font-weight: bold;
  line-height: 16px;
  letter-spacing: 1px;
  background-color: var(--theme-color3);
  border: 1px solid var(--theme-color3);
}

#submit button:hover{
  background-color: var(--theme-color6);
  border: 1px solid var(--theme-color6);
}

#submit button.disabled{
  background-color: var(--theme-color7);
  border: 1px solid var(--theme-color7);
}
</style>
