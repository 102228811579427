<template>
  <b-modal id="result_modal"
          ref="result_modal"
           ok-title="Close"
           ok-only
           centered>
    {{result}}
  </b-modal>
</template>

<script>
export default{
  name : "ResultModal",

  computed : {
    result () {
      return this.$store.state.global_result;
    }
  }
}
</script>

<style>
#result_modal {
  text-align: center;
  font-family: var(--theme-font1);
  font-weight: bold;
  font-size: 22px;
}

#result_modal___BV_modal_header_{
  display: none;
}

#result_modal___BV_modal_body_{
  padding-top: 25px;
}

#result_modal___BV_modal_footer_{
  border: none;
}

#result_modal___BV_modal_footer_ button{
  width: 100%;
}
</style>
