<template>
  <b-modal id="error_modal"
          ref="error_modal"
           ok-title="Close"
           ok-only
           centered>
    {{error}}
  </b-modal>
</template>

<script>
export default{
  name : "ErrorModal",

  computed : {
    error () {
      return this.$store.state.global_error;
    }
  }
}
</script>

<style>
#error_modal{
  text-align: center;
  font-family: var(--theme-font1);
  font-weight: bold;
  font-size: 22px;
}

#error_modal___BV_modal_header_{
  display: none;
}

#error_modal___BV_modal_body_{
  padding-top: 25px;
}

#error_modal___BV_modal_footer_{
  border: none;
}

#error_modal___BV_modal_footer_ button{
  width: 100%;
  background-color: var(--theme-color11);
  border-color: var(--theme-color11);
}
</style>
