import util   from '../util'
import config from '../config/config'

export default {
  methods : {
    login (params) {
      return this.$http.post(config.BACKEND_URL + "/login", params)
                       .then(function(response){
                         this.$setCookie("authToken", response.body.authToken);
                         this.load_user();

                       }.bind(this)).catch(function(err){
                         err = JSON.parse(err.bodyText).error;
                         const err_msg = "Could not login: " + util.capitalize(err);

                         this.$store.commit('set_global_error', err_msg);
                         this.$bvModal.show('error_modal');
                     })
    },

    logout () {
      return this.$http.delete(config.BACKEND_URL + "/logout", this.auth_header)
                       .then(function(){
                         this.$removeCookie("authToken")
                       }.bind(this)).catch(function(){
                         this.$removeCookie("authToken")
                       }.bind(this));
    },

    register (params) {
      return this.$http.post(config.BACKEND_URL + "/register", params)
                       .then(function(){
                         const result = "Please check your inbox to complete registration";
                         this.$store.commit('set_global_result', result);
                         this.$bvModal.show('result_modal');

                       }.bind(this)).catch(function(err){
                         err = JSON.parse(err.bodyText).error;
                         const err_msg = "Could not register: " + util.capitalize(err);

                         this.$store.commit('set_global_error', err_msg);
                         this.$bvModal.show('error_modal');
                       });
    },


    load_user(){
      return this.$http.get(config.BACKEND_URL + "/user", this.auth_header)
                       .then(function(response){
                         this.$store.commit('set_user', response.body);
                         this.load_filters();
                         this.load_sinks();

                       }.bind(this)).catch(function(){
                         this.logout();

                         const err_msg = "Session expired, you have been logged out";
                         this.$store.commit('set_global_error', err_msg);
                         this.$bvModal.show('error_modal');
                       }.bind(this));
    },

    ///

    load_filters (){
      return this.$http.get(config.BACKEND_URL + "/filters", this.auth_header)
                       .then(function(filters){
                         filters = JSON.parse(filters.bodyText);
                         this.$store.commit('set_filters', filters);

                       }.bind(this)).catch(function(err){
                         err = JSON.parse(err.bodyText).error;
                         const err_msg = "Could not load server data: " + util.capitalize(err);

                         this.$store.commit('set_global_error', err_msg);
                         this.$bvModal.show('error_modal');
                       })
    },

    create_filter (params){
      return this.$http.post(config.BACKEND_URL + "/filter", params, this.auth_header)
                       .catch(function(err){
                         err = JSON.parse(err.bodyText).error;
                         const err_msg = "Could not create alert: " + util.capitalize(err);

                         this.$store.commit('set_global_error', err_msg);
                         this.$bvModal.show('error_modal');
                       })
    },

    delete_filter (id){
      return this.$http.delete(config.BACKEND_URL + "/filter/" + id, this.auth_header)
                       .catch(function(err){
                         err = JSON.parse(err.bodyText).error;
                         const err_msg = "Could not delete alert: " + util.capitalize(err);

                         this.$store.commit('set_global_error', err_msg);
                         this.$bvModal.show('error_modal');
                       })
    },

    ///

    load_sinks (){
      return this.$http.get(config.BACKEND_URL + "/sinks", this.auth_header)
                       .then(function(sinks){
                         sinks = JSON.parse(sinks.bodyText);
                         this.$store.commit('set_sinks', sinks);

                       }.bind(this)).catch(function(err){
                         err = JSON.parse(err.bodyText).error;
                         const err_msg = "Could not load server data: " + util.capitalize(err);

                         this.$store.commit('set_global_error', err_msg);
                         this.$bvModal.show('error_modal');
                       })
    },

    create_sink (params){
      return this.$http.post(config.BACKEND_URL + "/sink", params, this.auth_header)
                       .catch(function(err){
                         err = JSON.parse(err.bodyText).error;
                         const err_msg = "Could not create alert: " + util.capitalize(err);

                         this.$store.commit('set_global_error', err_msg);
                         this.$bvModal.show('error_modal');
                       })
    },
  }
}
