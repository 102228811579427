<template>
  <b-modal id="register_modal"
           ref="register_modal"
           title="Sign up"
           ok-title="Register"
           centered
           @shown="shown"
           @ok="submit"
           :ok-disabled="!is_valid">
    <b-form>
      <b-form-group label="Email">
        <b-form-input v-model="email"
                  placeholder="Enter email"
                         type="email"
                 @keyup.enter="submit"
                         required>
        </b-form-input>
      </b-form-group>

      <b-form-group label="Password">
        <b-form-input v-model="password"
                  placeholder="Enter password"
                         type="password"
                 @keyup.enter="submit"
                         required>
        </b-form-input>
      </b-form-group>

      <b-form-group label="Confirm Password">
        <b-form-input v-model="confirm_password"
                  placeholder="Enter password"
                         type="password"
                 @keyup.enter="submit"
                         required>
        </b-form-input>
      </b-form-group>

      <b-form-group>
        <b-form-checkbox v-model="tos" required>
          I agree to the
          <a href="https://blocktr.ac/terms" target="_blank">
            Terms of Service
          </a>
        </b-form-checkbox>
      </b-form-group>
    </b-form>
  </b-modal>
</template>

<script>
import ServerAPI from '../mixins/server_api'

export default {
  name: 'RegisterModal',

  mixins : [ServerAPI],

  data (){
    return {
                 email : '',
              password : '',
      confirm_password : '',
                   tos : false
    }
  },

  computed : {
    have_email () {
      return this.email != '';
    },

    have_password () {
      return this.password != '';
    },

    have_confirm_password () {
      return this.confirm_password != '';
    },

    same_passwords () {
      return this.password == this.confirm_password;
    },

    tos_agreed () {
      return this.tos;
    },

    is_valid (){
      return this.have_email            &&
             this.have_password         &&
             this.have_confirm_password &&
             this.same_passwords        &&
             this.tos_agreed;
    }
  },

  methods : {
    shown () {
      this.email = '';
      this.password = '';
      this.confirm_password = '';
      this.tos = false;
    },

    submit () {
      if(!this.is_valid) return;

      this.$refs.register_modal.hide()

      var register_params = {
           email : this.email,
        password : this.password
      };

      const in_progress_filter = this.$store.state.in_progress_filter;
      if(in_progress_filter){
        register_params.filter = in_progress_filter;
        this.$store.commit('clear_in_progress_filter');
      }

      const in_progress_sink = this.$store.state.in_progress_sink;
      if(in_progress_sink){
        register_params.sink = in_progress_sink;
        this.$store.commit('clear_in_progress_sink');
      }

      if(in_progress_filter && in_progress_sink)
        register_params.filter_sink = true;

      this.register(register_params);
    }
  }
}
</script>

<style>
#register_modal{
  font-family: var(--theme-font1);
}

#register_modal___BV_modal_title_{
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
}

</style>
