import Landing from './Landing'

const routes = [
  { path : '/' , component : Landing }
]

///

import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

export default new VueRouter({
  mode : "history",
  routes : routes,

  // always scroll to top on nav
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
})
