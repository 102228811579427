<template>
  <b-modal id="login_modal"
          ref="login_modal"
           title="Login"
           ok-title="Login"
           centered
           @shown="shown"
           @ok="submit"
           :ok-disabled="!is_valid">
    <b-form>
      <b-form-group label="Email">
        <b-form-input v-model="email"
                  placeholder="Enter email"
                         type="email"
                 @keyup.enter="submit"
                         required>
        </b-form-input>
      </b-form-group>

      <b-form-group label="Password">
        <b-form-input v-model="password"
                  placeholder="Enter password"
                         type="password"
                 @keyup.enter="submit"
                         required>
        </b-form-input>
      </b-form-group>
    </b-form>

    <div id="show_register" @click="show_register">
      Don't have an account?
    </div>
  </b-modal>
</template>

<script>
import Authentication from '../mixins/authentication'
import ServerAPI      from '../mixins/server_api'

export default {
  name: 'LoginModal',

  mixins : [
    Authentication,
    ServerAPI
  ],

  data (){
    return {
         email : '',
      password : ''
    }
  },

  computed : {
    have_email () {
      return this.email != '';
    },

    have_password () {
      return this.password != '';
    },

    is_valid (){
      return this.have_email && this.have_password;
    }
  },

  methods : {
    shown () {
      this.email = '';
      this.password = '';
    },

    show_register(){
      this.$refs.login_modal.hide()
      this.$bvModal.show('register_modal');
    },

    submit () {
      if(!this.is_valid) return;

      this.$refs.login_modal.hide()

      var login_params = {
           email : this.email,
        password : this.password
      };

      const in_progress_filter = this.$store.state.in_progress_filter;
      if(in_progress_filter){
        login_params.filter = in_progress_filter;
        this.$store.commit('clear_in_progress_filter');
      }

      const in_progress_sink = this.$store.state.in_progress_sink;
      if(in_progress_sink){
        login_params.sink = in_progress_sink;
        this.$store.commit('clear_in_progress_sink');
      }

      if(in_progress_filter && in_progress_sink)
        login_params.filter_sink = true;

      this.login(login_params);
    }
  }
}
</script>

<style>
#login_modal{
  font-family: var(--theme-font1);
}

#login_modal___BV_modal_title_{
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
}

#show_register{
  cursor: pointer;
  color: blue;
  text-align: right;
}
</style>
