const WAValidator = require('wallet-address-validator');

export default {
  capitalize (str) {
    return str[0].toUpperCase() + str.slice(1);
  },

  is_valid_email (test){
    return /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(test);
  },

  is_valid_wallet (blockchain, address){
    return !!WAValidator.validate(address, blockchain);
  }
}
