<template>
  <b-modal id="new_watch_modal"
           ref="new_watch_modal"
           v-model="is_shown"
           title="Create Alert"
           ok-title="CREATE ALERT"
           ok-only
           centered
           @shown="shown"
           @ok="submit"
           :ok-disabled="!is_valid">
    <WatchParams ref="watch_params" />
  </b-modal>
</template>

<script>
import WatchParams from './WatchParams'
import IsMounted   from '../mixins/is_mounted'

export default {
  name: 'NewWatchModal',

  mixins : [
    IsMounted
  ],

  components : {
    WatchParams
  },

  data () {
    return {
      is_shown : false
    }
  },

  computed : {
    is_valid () {
      if(!this.is_mounted || !this.is_shown) return false;

      return this.$refs.watch_params.is_valid;
    }
  },

  methods : {
    shown () {
      this.$refs.watch_params.reset();
    },

    submit () {
      this.$refs.watch_params.submit();
    }
  }
}
</script>

<style scoped>
</style>

<style>
#new_watch_modal___BV_modal_content_{
  /*background: linear-gradient(180deg, #6BBAFF 0%, #6CFF7B 100%);*/
}

#new_watch_modal___BV_modal_header_{
  border: none;
}

#new_watch_modal___BV_modal_title_{
  font-family: var(--theme-font1);
  font-weight: bold;
  font-size: 34px;
  line-height: 40px;
  letter-spacing: 0.5px;
  color: var(--theme-color2);
}

#new_watch_modal___BV_modal_content_ .btn{
  width: 100%;
  font-family: var(--theme-font2);
  background-color: var(--theme-color3);
  border: 1px solid var(--theme-color3);
}

#new_watch_modal___BV_modal_content_ .btn:hover{
  background-color: var(--theme-color6);
  border: 1px solid var(--theme-color6);
}

#new_watch_modal___BV_modal_content_ .btn.disabled{
  background-color: var(--theme-color7);
  border: 1px solid var(--theme-color7);
}

#new_watch_modal___BV_modal_footer_{
  border: none;
}
</style>
